import * as React from 'react';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { Query, MutationFn } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import * as qs from 'qs';

import { routes, updateURLQuery, path } from 'inkp-routes/public';
import { Subscribe } from 'infra-frontend/helpers/apollo';
import { Order } from 'inkp-order-sdk/types.g';
import { isUserRegistered } from 'inkp-user-sdk/user';
import { User } from 'inkp-user-sdk/types.g';
import { Design, Mutation as MutationDesignResults, MutationcreateDesignsArgs } from 'inkp-design-sdk/types.g';
import Alert from 'inkp-components/dist/Components/Alert';
import Loading from 'inkp-components/dist/Components/Loading';

import DesignTool from '../DesignTool/index2';
import {
  CREATE_DESIGNS_MUTATION,
  MutationCreateDesigns,
  MutationSwapOrderDesign,
  ORDER_PRODUCT_DESIGNS_BY_EMAIL_QUERY,
  ORDER_PRODUCT_DESIGNS_QUERY,
  SWAP_ORDER_DESIGN_MUTATION,
} from '../DesignTool/queries';
import { CURRENT_USER_QUERY } from '../../util/login';

import DesignCart from '../../states/global/designCart';
import DesignToolStateContainer from '../DesignTool/DesignToolStateContainer';

class TypedSubscribe extends Subscribe<[DesignToolStateContainer]> {}

interface RedesignOnNextProps {
  order: Order;
  itemIndexes: number[];
  designCart: DesignCart;
  currentUser: User;
  createDesigns: MutationFn<MutationDesignResults, MutationcreateDesignsArgs>;
}

function RestoreDesign(props: {
  designCart: any;
  designToolStateContainer: DesignToolStateContainer;
  history: any;
  itemIndexes: number[];
  location: any;
  match: any;
  onNext: () => void;
  order: Order;
}) {
  React.useEffect(() => {
    props.designCart.restoreFromOrder(props.order, itemIndexes).then(() => {
      props.history.replace(updateURLQuery(props.location.pathname, params, queryParams, {}));
    });
  }, []);

  const { order, itemIndexes, designCart } = props;
  const params: { [key: string]: string } = {};
  const orderItems = itemIndexes.map((index) => order.quote.items[index]);
  const selectedProductId = _.get(orderItems[0], 'product.product.id');
  const selectedProductColor = _.get(orderItems[0], 'product.productItem.color');
  if (selectedProductId) {
    params.product = selectedProductId;
    if (selectedProductColor) {
      params.color = selectedProductColor;
    }
  }
  const queryParams = qs.parse(`${props.location.search}`, { ignoreQueryPrefix: true });

  if (order) {
    return (
      <DesignTool
        designCart={designCart}
        designCartState={designCart.state}
        order={order}
        itemIndexes={itemIndexes}
        {...props}
      />
    );
  }

  return <DesignTool designCart={designCart} designCartState={designCart.state} {...props} />;
}

const onNext = ({ order, itemIndexes, designCart, currentUser, createDesigns }: RedesignOnNextProps) => {
  return () => {
    const item = order.quote.items && order.quote.items[itemIndexes[0]];
    const { design } = item;
    const { state: designState } = designCart;
    if (design) {
      const designInputs = designState.designs.map(({ sides }: { sides: any }) => {
        return { sides, userId: currentUser.id, shareableId: (design as Design).shareableId };
      });
      createDesigns({ variables: { designs: designInputs } });
    }
    return;
  };
};

interface RedesignPageParams {
  orderId: string;
}

export default function(props: RouteComponentProps<RedesignPageParams>) {
  const queryParams = qs.parse(`${props.location.search}`, { ignoreQueryPrefix: true });
  return (
    <Query query={CURRENT_USER_QUERY}>
      {({ loading: userLoading, data: userData }: any) => {
        if (userLoading) return <Loading />;
        const _isUserRegistered = isUserRegistered(userData.currentUser);
        const { currentUser } = userData;

        return (
          <Query
            query={ORDER_PRODUCT_DESIGNS_BY_EMAIL_QUERY}
            variables={{ id: props.match.params.orderId, email: queryParams.email }}
            skip={_isUserRegistered}
            fetchPolicy="network-only"
          >
            {({ loading: emailOrderLoading, error: emailOrderError, data: emailOrderData }: any) => (
              <Query
                query={ORDER_PRODUCT_DESIGNS_QUERY}
                variables={{ id: props.match.params.orderId }}
                skip={!_isUserRegistered}
                fetchPolicy="network-only"
              >
                {({ loading, error, data: orderData }: any) => {
                  if (emailOrderLoading || loading) return null;
                  if (emailOrderError || error) return <Redirect to={routes.app.base} />;

                  const order: Order | undefined =
                    _.get(emailOrderData, 'orderByIdAndEmail') || _.get(orderData, 'orderByIdAndUser');
                  if (!order)
                    return (
                      <div className="flex flex-col w-full px-1 md:w-container md:mx-auto">
                        <Alert
                          alerts={[
                            {
                              type: 'error',
                              title: 'Failed to lookup order',
                              content: `Could not find order ${props.match.params.orderId}`,
                            },
                          ]}
                        />
                      </div>
                    );
                  if (!queryParams || !queryParams.items || !_.isArray(queryParams.items))
                    return <Redirect to={routes.app.base} />;
                  return (
                    <Subscribe to={[DesignCart]} namespace="Redesign">
                      {(designCart: DesignCart) => (
                        <TypedSubscribe to={[DesignToolStateContainer]} namespace="RedesignDesignToolInteraction">
                          {(designToolStateContainer: DesignToolStateContainer) => {
                            const itemIndexes = queryParams.items.map((item: string) => parseInt(item));
                            return (
                              <MutationSwapOrderDesign
                                mutation={SWAP_ORDER_DESIGN_MUTATION}
                                onCompleted={(results: any) => {
                                  designToolStateContainer.setRedesignSuccess('Successfully submitted design.');
                                  return props.history.push({
                                    pathname: path(routes.app.order.getById, { orderId: order.id }),
                                    search: queryParams.email ? `?email=${encodeURIComponent(queryParams.email)}` : '',
                                  });
                                }}
                                onError={(mutationSwapOrderDesignError: ApolloError) => {
                                  Sentry.captureException(mutationSwapOrderDesignError);
                                  return designToolStateContainer.setRedesignError('Error submitting design.');
                                }}
                              >
                                {(swapOrderDesign) => (
                                  <MutationCreateDesigns
                                    mutation={CREATE_DESIGNS_MUTATION}
                                    onCompleted={({ createDesigns }: MutationDesignResults) => {
                                      const item = order.quote.items && order.quote.items[itemIndexes[0]];

                                      if (item.design && createDesigns && createDesigns.length > 0) {
                                        const createdDesign = createDesigns[0];
                                        if (createdDesign) {
                                          const swapDesignVariables = {
                                            orderId: order.id,
                                            oldDesignId: item.design.id,
                                            newDesignId: createdDesign.id,
                                          };
                                          swapOrderDesign({ variables: swapDesignVariables });
                                        }
                                      }
                                    }}
                                    onError={(mutationCreateDesignsError: ApolloError) => {
                                      Sentry.captureException(mutationCreateDesignsError);
                                      return designToolStateContainer.setRedesignError('Error submitting design.');
                                    }}
                                  >
                                    {(createDesigns) => {
                                      return (
                                        <RestoreDesign
                                          designCart={designCart}
                                          designToolStateContainer={designToolStateContainer}
                                          history={props.history}
                                          itemIndexes={itemIndexes}
                                          order={order}
                                          onNext={onNext({
                                            order,
                                            itemIndexes,
                                            designCart,
                                            currentUser,
                                            createDesigns,
                                          })}
                                          {...props}
                                        />
                                      );
                                    }}
                                  </MutationCreateDesigns>
                                )}
                              </MutationSwapOrderDesign>
                            );
                          }}
                        </TypedSubscribe>
                      )}
                    </Subscribe>
                  );
                }}
              </Query>
            )}
          </Query>
        );
      }}
    </Query>
  );
}
